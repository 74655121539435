import { useEffect } from "react";
import { BrowserRouter, Route, Routes, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import Home from "./Home";
import NotFound from "./404";
// import Members from "./members"
import theme from "../theme/theme";
import { ThemeProvider } from "@mui/material/styles";
import sanityclient from "../client";
import { useSelector, useDispatch } from "react-redux";
import { ACTIONS as DATA_ACTIONS } from "../redux/reducers/DataReducer";
import SiteBuilder from "./siteBuilder";
import Header from "../components/header";
import Menu from "../components/menu";
import Footer from "../components/footer";
import Publications from "../pages/publications";
import Publication from "./publication";
import Contact from "./contact";
import Projects from "./projects";
import Project from "./project";
import ImageChecker from "./imagesChecker";
import Papers from "./papers";
// import Winterschool from "./Winterschool";
// import Projects from "./projects";

// // import themer
// // import './App.css';
// /*

// TODO:
//   - bring in client
// - make project page
// - make member page
// - make

// */

const DataHelper = () => {
  const data = useSelector((state) => state.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!data.client && dispatch) {
      const cli = sanityclient(dispatch);
      dispatch({ type: DATA_ACTIONS.SET_CLIENT, client: cli });
    }

    if (data.client && !data.graphics) {
      data.client.getGraphics().then((graphics) => {
        dispatch({ type: DATA_ACTIONS.SET_GRAPHICS, graphics });
      });
    }

    if (data.client && !data.settings) {
      data.client.getSettings().then((settings) => {
        dispatch({ type: DATA_ACTIONS.SET_SETTINGS, settings });
      });
    }

    if (data.client && !data.collaborators) {
      data.client.getCollaborators().then((collaborators) => {
        dispatch({ type: DATA_ACTIONS.SET_COLLABORATORS, collaborators });
      });
    }

    if (data.client && !data.categories) {
      data.client.getCategories().then((categories) => {
        dispatch({ type: DATA_ACTIONS.SET_CATEGORIES, categories });
      });
    }

    // Load calendar data
    if (data.client && !data.calendar) {
      data.client.getCalendar().then((calendar) => {
        dispatch({ type: DATA_ACTIONS.SET_CALENDAR, calendar });
      });
    }

    // Load news data
    if (data.client && !data.news) {
      data.client.getNews().then((news) => {
        dispatch({ type: DATA_ACTIONS.SET_NEWS, news });
      });
    }

    // Load projects view data
    if (data.client && !data.projectsView) {
      data.client.getProjectsView().then((projectsView) => {
        dispatch({ type: DATA_ACTIONS.SET_PROJECTS_VIEW, projectsView });
      });
    }

    // Load homepage images
    if (data.client && !data.homePageImages) {
      data.client.getHomePageImages().then((homePageImages) => {
        dispatch({ type: DATA_ACTIONS.SET_HOMEPAGE_IMAGES, homePageImages });
      });
    }

    // Load publications view data
    if (data.client && !data.publicationsView) {
      data.client.getPublicationsView().then((publicationsView) => {
        dispatch({
          type: DATA_ACTIONS.SET_PUBLICATIONS_VIEW,
          publicationsView,
        });
      });
    }
  }, [data, dispatch]);

  return null;
};

function CMSRedirect() {
  useEffect(() => {
    window.location.href = "https://pietersteyaert.sanity.studio/desk";
  }, []);

  return null;
}

function PS() {
  const history = createBrowserHistory();
  return (
    <BrowserRouter history={history}>
      <DataHelper />
      <ThemeProvider theme={theme}>
        <Header />
        <Menu />
        <div className="main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/publications" element={<Publications />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/project/:slug" element={<Project />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/images" element={<ImageChecker />} />
            <Route path="/publication/:slug" element={<Publication />} />
            <Route path="/articles" element={<Papers />} />
            <Route path="/CMS" element={<CMSRedirect />} />
            <Route path="/studio" element={<CMSRedirect />} />

            <Route path="/builder" element={<SiteBuilder />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default PS;
