import React from "react";
import { useSelector } from "react-redux";
import { Text } from "../theme/theme";
import BlockContent from "@sanity/block-content-to-react";

const Footer = () => {
  const settings = useSelector((state) => state.data.settings);

  if (!settings) {
    return "∆";
  } else {
    return (
      <div
        style={{
          textAlign: "center",
          marginLeft: "30vw",
          marginRight: "10vw",
          marginTop: "50px",
        }}
      >
        <i>
          <Text>
            <BlockContent blocks={settings.footer} />
          </Text>
        </i>
      </div>
    );
  }
};

export default Footer;
