export const ACTIONS = Object.freeze({
  // SET_NAV: Symbol("ui/setNavigationStatus"),
  // SET_PROJ_HOVER: Symbol("ui/setProjectHover"),
  // SET_APPEAR:Symbol("ui/setAppear"),
  // SET_CHANGE_PAGE:Symbol("ui/setChangePage")
});

const initialState = {
  // navstate: NAVSTATES.DEFAULT,
  // projectHover: null,
  // appear:new Date(),
  // changedPage: true,
  test: 0
};

const handler = (state = initialState, action) => {
  switch (action.type) {
    default: {
      return {
        ...state
      };
    }
  }
};

export default handler;