import React, { useEffect, useState } from "react";
import {
  Box,
  Title,
  SubTitle,
  Text,
  Button,
  Space,
  CalendarItem,
  LookupResponsiveimage,
  ButtonList,
  getAcademicImages,
  blocksToText,
  LookupImageList,
  CollaboratorList,
} from "../theme/theme";
import { useParams } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import Spinner from "../components/spinner";
import {
  Container,
  Grid,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";
import BlockContent from "@sanity/block-content-to-react";

import settings from "../settings.json";
import { Link } from "react-router-dom";
import { AvatarList } from "../components/avatar";
import { Subtitles } from "@mui/icons-material";

import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Masonry from "@mui/lab/Masonry";

import Paper from "@mui/material/Paper";

const Publication = (props) => {
  const publicationsView = useSelector((state) => state.data.publicationsView);
  const [publication, setPublication] = useState(null);
  const params = useParams();

  useEffect(() => {
    if (publicationsView && params && !publication && params.slug) {
      var np = null;
      publicationsView.publications.forEach((p) => {
        if (p.slug === params.slug) {
          np = p;
        }
      });
      setPublication(np);
    }
  }, [publication, publicationsView, params]);

  if (publicationsView) {
    if (publication) {
      const Meta = (
        <MetaTags>
          <title>{publication.title}</title>
          <meta
            name="description"
            content={blocksToText(publication.description)}
          />
          <meta property="og:title" content={publication.title} />
        </MetaTags>
      );

      return (
        <>
          {Meta}
          <Grid container spacing={20}>
            <Grid item xs={12} sm={12} lg={6}>
              <Title>♢ {publication.title}</Title>
              <CollaboratorList collaborators={publication.collaborators} />
              <Text>
                <i>
                  {" "}
                  <BlockContent blocks={publication.description} />{" "}
                </i>
              </Text>
              <br />
              <ButtonList buttons={publication.buttons} />
              <br />
              <br />
              <SubTitle>Abstract </SubTitle>
              <Text>
                <BlockContent blocks={publication.abstract} />
              </Text>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <LookupImageList graphiclist={publication.images} />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: { xs: "none", sm: "none", md: "block" } }}
            ></Grid>
          </Grid>
        </>
      );
    } else {
      return <Text>Can't find publication</Text>;
    }
  } else {
    return null;
  }
};

export default Publication;
