import sanityClient from "@sanity/client";
import envi from "./environment";
import settings from "./connector_settings.json";
import { ACTIONS as DATA_ACTIONS } from "../redux/reducers/DataReducer";
import imageUrlBuilder from "@sanity/image-url";
import {
  graphicsQuery,
  settingsQuery,
  collaboratorsQuery,
  categoryQuery,
  calendarQuery,
  newsQuery,
  projectsViewQuery,
  publicationsViewQuery,
  homePageImages,
} from "./queries";
const prevFetched = {};

export const client = (dispatch) => {
  const environment = envi();
  let _ = settings.settings;
  let news = [];
  const sc = sanityClient({
    projectId: _.projectId,
    dataset: _.dataset,
    token: _.token, // or leave blank to be anonymous user
    ignoreBrowserTokenWarning: true,
    useCdn: false,
    apiVersion: "2021-08-31",
  });

  const fetch = (query) => {
    return new Promise((resolve, reject) => {
      if (prevFetched[query]) {
        if (environment.dev) {
          console.log("reused cached query");
        }
        resolve(prevFetched[query]);
      } else {
        sc.fetch(query)
          .then((data) => {
            prevFetched[query] = data;
            resolve(data);
          })
          .catch(reject);
      }
    });
  };

  const builder = imageUrlBuilder(sc);

  const squareImage = (url, width) => {
    return builder.image(url).width(width).height(width).url();
  };

  if (environment.dev) {
    environment.printstatus();
  }

  const getUrl = (selector) => {
    return `"img" : ${selector}.asset->url`;
  };

  const generateQuery = (query) => {
    return new Promise((resolve, reject) => {
      fetch(query).then((data) => {
        resolve(data);
      });
    });
  };

  const getGraphics = () => generateQuery(graphicsQuery);
  const getSettings = () => generateQuery(settingsQuery);
  const getCollaborators = () => generateQuery(collaboratorsQuery);
  const getCategories = () => generateQuery(categoryQuery);
  const getCalendar = () => generateQuery(calendarQuery);
  const getNews = () => generateQuery(newsQuery);

  // const getProjectsView = () => {
  //   return new Promise((resolve, reject) => {
  //     fetch(projectsViewQuery).then((data) => {
  //       if (data && data.projects) {
  //         data.projects.forEach(({ _id, SEADSID }) => {
  //           console.log(SEADSID);
  //         });
  //       }

  //       resolve(data);
  //     });
  //   });
  // };

  const getProjectsView = () => {
    return new Promise((resolve, reject) => {
      // Create a temporary SEADSCLIENT within the function
      const SEADSCLIENT = sanityClient({
        projectId: "t39t9igo", // SEADS projectId
        dataset: "production", // SEADS dataset
        ignoreBrowserTokenWarning: true,
        useCdn: false, // Ensure you fetch fresh data
        apiVersion: "2021-08-31", // Specify the API version
      });

      // Fetch the projects from your local site
      fetch(projectsViewQuery)
        // .then((response) => response.json()) // Assuming fetch works without json conversion in your context
        .then((projectsViewData) => {
          if (projectsViewData && projectsViewData.projects) {
            // For each project, check for SEADSID and fetch from seads.network if it exists
            const projectPromises = projectsViewData.projects.map((project) => {
              const { _id, SEADSID } = project;

              // If SEADSID exists and is not empty, fetch the corresponding data from Sanity CMS using SEADSCLIENT
              if (SEADSID) {
                const sanityQuery = `*[_id == "${SEADSID}"][0]{
                title,
                "header" : cover->image.asset->url,
                "directimages" : included_images[]->image.asset->url,
                "directimages2": resources[]{
                "item": @->,
                }[item._type == "image_kbi"].item.image.asset->url,
                "collaborators" : members[]->{"title" : firstname+" "+lastname, "url":website},
                description,
                  "buttons": resources[]{
    "item": @->,
  }[item._type == "link_kbi"]{
    "_key": item._id,
    "title": item.title,
    "url": item.url
  }
              }`;

                // Fetch data from Sanity CMS using the temporary SEADSCLIENT
                return SEADSCLIENT.fetch(sanityQuery).then((sanityData) => {
                  if (sanityData) {
                    // Merge the data from Sanity with the existing project data
                    return { ...project, ...sanityData };
                  }
                  return project; // Return the original project if no result is found
                });
              }

              // If no SEADSID, return the project unmodified
              return Promise.resolve(project);
            });

            // Wait for all the project data to be fetched
            Promise.all(projectPromises)
              .then((populatedProjects) => {
                projectsViewData.projects = populatedProjects;
                resolve(projectsViewData);
              })
              .catch(reject);
          } else {
            resolve([]);
          }
        })
        .catch(reject);
    });
  };

  const getPublicationsView = () => generateQuery(publicationsViewQuery);
  const getHomePageImages = () => generateQuery(homePageImages);

  const makeImageReference = (_ref) => {
    return {
      _type: "image",
      asset: {
        _type: "reference",
        _ref,
      },
    };
  };

  return {
    fetch,
    environment,
    squareImage,
    getGraphics,
    getSettings,
    getCollaborators,
    getCategories,
    getCalendar,
    getNews,
    getProjectsView,
    getPublicationsView,
    getHomePageImages,
  };
};

export default client;
