export const graphicsQuery = `
*[_type == "graphic"]{
  _id,
  "url" : img.asset->url,
  "related" : *[_type != "settings" && references(^._id)]  
}
`;

export const settingsQuery = `
*[_type == "settings"][0] {
  _id,
  "header": header.asset->url,
  socials[]{
    title,
    url
  },
  intro,
  footer,
  contact
}
`;

export const calendarQuery = `
*[_type == "calendarnew"][].calendarevents{
  _key,
  title, 
  url, 
  target->{
    _type, 
    "slug": slug.current 
  }}
`;

export const newsQuery = `
*[_type == "newsitem"] | order(_createdAt desc) {
  title,
  _id,
  description,
  buttons[]{
    _key,
    title,
    url,
    target->{
      _type, 
    "slug": slug.current 
    }
  },
  graphics[]{
    _ref
  }
}
`;

export const projectsViewQuery = `
*[_id == "drafts.projectsview"][0] {
  projectsText,
  projectButtons[]{
    title,
    url,
    target->{
      _type,
      "slug" : slug.current
    }
  },
  projects[]->{
    _id,
    title,
    "slug" : slug.current,
    banner,
    SEADSID,
    year,
    "header": header.asset->url,
    description,
    buttons[]{
      _key,
      title,
      url,
      target->{
        _type,
        "slug": slug.current
      }
    },
    collaborators[]->{
      title,
      url
    },
    vimeolink,
    "images" : projectimages[]._ref,
    "textColor": textColor.hex,
    "bgroundColor":bgroundColor.hex,
    optionalSegments[]{
      _key,
      title,
      description,
      "images" : images[]._ref,
      "textColor": textColor.hex,
      "bgroundColor":bgroundColor.hex,
    },
    "categories" : CategoryRef[]->title,
  }
}
`;

export const publicationsViewQuery = `
*[_id == "publicationsview"][0] {
  publications[]->{
    title,
    "slug" : slug.current,
    description,
    abstract,
    buttons[]{
      _key,
      title,
      url,
      target->{
        _type,
        "slug" : slug.current
      }
    },
    collaborators[]->{
      _type,
      title
    },
    "images" : projectimages[]._ref,
  },
  publicationsText,
  publicationButtons[]{
    key_,
    title,
    url,
    target->{
      _type,
      "slug": slug.current
    }
  }
}
`;

export const homePageImages = `
  *[_id == "homepageImages"][0].images[]._ref
`;

export const collaboratorsQuery = `
*[_type == "collaborator"]{
  _id,
  title,
  url,
}
`;

export const categoryQuery = `
*[_type == "category"] | order(priority asc) {
  _id,
  title
}
`;

// export const settingsQuery = `
// *[_type == "settings"][0]{
//   "header" : header.asset->url,
//   intro,
//   "images" : homepageimages[]._ref,
//   calendar[]{
//       title,
//       _key,
//       url,
//       target->{ _type, "slug": slug.current }
//   },
//   news[]{
//     _key,
//     title,
//     description,
//     "graphic" : graphic._ref,
//     buttons[]{
//       title,
//       _key,
//       url,
//       target->{ _type, "slug": slug.current }
//       }
//     },
//   footer,
//   socials,
// publicationsText,
// publicationButtons[]{
//   title,
//   _key,
//   url,
//   target->{ _type, "slug": slug.current }
// },
//   publications[]->
//     {
//       title,
//       "slug": slug.current,
//       description,
//       abstract,
//       "images" : projectimages[]._ref,
//       collaborators[]->{title, url},
//       buttons[]{
//         title,
//         _key,
//         url,
//         target->{ _type, "slug": slug.current }
//       },
//     },
//  projectsText,
//  projectButtons[]{
//   title,
//   _key,
//   url,
//   target->{ _type, "slug": slug.current }
// },
//   projects[]->
//   {
//     title,
//     banner,
//     "slug": slug.current,
//     _id,
//     year,
//     "categories" : CategoryRef[]->title,
//     "header" : header.asset->url,
//     description,
//     vimeolink,
//     collaborators[]->{title, url},
//     buttons[]{
//       title,
//       _key,
//       url,
//       target->{ _type, "slug": slug.current }
//     },
//     "images" : projectimages[]._ref,
//     },
//     contact
// }
// `
