import { homePageImages } from "../../client/queries";

export const ACTIONS = Object.freeze({
  SET_CLIENT: Symbol("data/client"),
  SET_GRAPHICS: Symbol("data/graphics"),
  SET_SETTINGS: Symbol("data/settings"),
  SET_COLLABORATORS: Symbol("data/collaborators"),
  SET_CATEGORIES: Symbol("data/categories"),
  SET_CATEGORY: Symbol("data/category"),
  SET_CALENDAR: Symbol("data/calendar"),
  SET_NEWS: Symbol("data/news"),
  SET_PROJECTS_VIEW: Symbol("data/projectsView"),
  SET_PUBLICATIONS_VIEW: Symbol("data/publicationsView"),
  SET_HOMEPAGE_IMAGES: Symbol("data/homepageImages"),
});

const initialState = {
  client: null,
  graphics: null,
  settings: null,
  collaborators: null,
  categories: null,
  category: null,
  calendar: null,
  news: null,
  projectsView: null,
  publicationsView: null,
  homePageImages: null,
};

const handler = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_CLIENT: {
      return {
        ...state,
        client: action.client,
      };
    }
    case ACTIONS.SET_GRAPHICS: {
      return {
        ...state,
        graphics: action.graphics.reduce(
          (obj, item) =>
            Object.assign(obj, {
              [item._id]: {
                url: item.url,
                _id: item._id,
                related: item.related,
              },
            }),
          {}
        ),
      };
    }
    case ACTIONS.SET_SETTINGS: {
      return {
        ...state,
        settings: action.settings,
      };
    }
    case ACTIONS.SET_COLLABORATORS: {
      return {
        ...state,
        collaborators: action.collaborators,
      };
    }
    case ACTIONS.SET_CATEGORIES: {
      return {
        ...state,
        categories: action.categories,
      };
    }
    case ACTIONS.SET_CATEGORY: {
      return {
        ...state,
        category: action.category,
      };
    }
    case ACTIONS.SET_CALENDAR: {
      return {
        ...state,
        calendar: action.calendar,
      };
    }
    case ACTIONS.SET_NEWS: {
      return {
        ...state,
        news: action.news,
      };
    }
    case ACTIONS.SET_PROJECTS_VIEW: {
      return {
        ...state,
        projectsView: action.projectsView,
      };
    }
    case ACTIONS.SET_PUBLICATIONS_VIEW: {
      return {
        ...state,
        publicationsView: action.publicationsView,
      };
    }
    case ACTIONS.SET_HOMEPAGE_IMAGES: {
      return {
        ...state,
        homePageImages: action.homePageImages,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default handler;
