import React, { useEffect, useState } from "react";
import {
  Box,
  Title,
  SubTitle,
  Text,
  Button,
  Space,
  CalendarItem,
  LookupResponsiveimage,
  ButtonList,
  getAcademicImages,
  LookupImageList,
} from "../theme/theme";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import Spinner from "../components/spinner";
import {
  Container,
  Grid,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";
import BlockContent from "@sanity/block-content-to-react";

import settings from "../settings.json";
import { Link } from "react-router-dom";
import { AvatarList } from "../components/avatar";
import { Subtitles } from "@mui/icons-material";

import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Masonry from "@mui/lab/Masonry";

import Paper from "@mui/material/Paper";

const Intro = () => {
  const publicationsView = useSelector((state) => state.data.publicationsView);

  if (publicationsView) {
    return (
      <div>
        <Title>/ RESEARCH</Title>
        <Text>
          <BlockContent blocks={publicationsView.publicationsText} />
        </Text>

        <ButtonList buttons={publicationsView.publicationButtons} />
      </div>
    );
  } else {
    return null;
  }
};

const AcademicImages = () => {
  const graphics = useSelector((state) => state.data.graphics);
  const [images, setImages] = useState(null);

  useEffect(() => {
    if (graphics && !images) {
      setImages(getAcademicImages(graphics));
    }
  });

  if (images) {
    return (
      <ImageList variant="masonry" cols={1} gap={8}>
        {images.map((item) => (
          <ImageListItem key={item}>
            <img srcSet={`${item}`} src={`${item}`} alt={item} loading="lazy" />
          </ImageListItem>
        ))}
      </ImageList>
    );
  } else {
    return null;
  }
};

const PublicationList = () => {
  const publicationsView = useSelector((state) => state.data.publicationsView);

  if (publicationsView) {
    return (
      <div>
        <Title>♢ PUBLICATIONS </Title>
        <br />
        {publicationsView.publications.map((i) => {
          return (
            <Link key={i.slug} to={`/publication/${i.slug}`}>
              <SubTitle>{i.title}</SubTitle>
              <Text>
                <i>
                  <BlockContent blocks={i.description} />
                </i>
              </Text>

              <Button>...More</Button>
            </Link>
          );
        })}
      </div>
    );
  } else {
    return null;
  }

  return null;
};

const Publications = () => {
  const Meta = (
    <MetaTags>
      <title>{"Pieter Steyaert / Publications"}</title>
      <meta
        name="description"
        content={
          "Publications overview of Pieter, who is an artist and researcher based in Copenhagen, Denmark"
        }
      />
      <meta property="og:title" content={"Pieter Steyaert / Publications"} />
    </MetaTags>
  );

  return (
    <>
      {Meta}
      <Grid container spacing={20}>
        <Grid item xs={12} sm={12} lg={6}>
          <Intro />
          <br />
          <br />
          <Grid
            item
            xs={12}
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "block" },
            }}
          >
            <AcademicImages />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <PublicationList />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: { xs: "block", sm: "block", md: "block", lg: "none" },
          }}
        >
          <AcademicImages />
        </Grid>
      </Grid>

      {/* <HomeGraphicsMobile /> */}
    </>
  );
};

export default Publications;
